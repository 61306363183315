import levenshtein from 'js-levenshtein';
import moment from 'moment';
import { format } from 'date-fns';

const toCamelCase = (input) => {
  return input.toLowerCase().replace(/-(.)/g, (match, group1) => {
    return group1.toUpperCase();
  });
};

const camelToNormal = (input) => {
  let result = input.replace(/([A-Z])/g, ' $1');
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1);

  return finalResult;
};

const firstName = (string) => {
  string = string.split(' ');

  return string[0];
};

const ISOParser = (string, addFormat = false) => {
  if (!string) return 'None';

  if (typeof string == 'object') string = string[0];
  else if (typeof string == 'number') string = string * 1000;

  if (typeof string == 'string') string = string.replace(/\+/g, '');

  const format = moment(string).format(
    'Y-MM-DD hh:mm:ss A' + (addFormat ? ' ' + addFormat : '')
  );

  return format == 'Invalid date' ? 'None' : format;
};

const decodeBase64 = (string) => {
  return Buffer.from(string, 'base64').toString('ascii');
};

const toMonth = (number) => moment(number, 'M').format('MMMM');

const currency = (number) =>
  new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' })
    .format(number)
    .replace(/\D00$/, '');

const substr = (string, start, end) => {
  if (!string) return '';
  if (!end) end = string.length;

  if (string.length > end) return string.substring(start, end) + '...';

  return string.substring(start, end);
};

const isMatch = (match, pathname) => {
  if (typeof match === 'string') {
    return match === pathname;
  }

  return pathname.search(match) > -1;
};

const isMatches = (matches, pathname) => {
  return matches.map((m) => isMatch(m, pathname)).includes(true);
};

const getUrl = (str) => {
  const extract = str.match(
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g
  );

  return extract && extract.length > 0 ? extract[0] : str;
};

const getAssocId = (identifiers, name = 'kredibel') => {
  if (!Array.isArray(identifiers)) {
    return '';
  }

  const f = identifiers.find((i) => i.name === name);

  if (!f) {
    return '';
  }

  return f.id;
};

const stringCompare = (str1, str2) => {
  return 1 - levenshtein(str1, str2) / Math.max(str1.length, str2.length);
};

const formatDate = (string, formatString = 'yyyy-MM-dd') => {
  const date = new Date(string);

  if (!string || date.toString() === 'Invalid Date') return '-';

  return format(date, formatString);
};

const toFixedIfNecessary = (value, dp = 2) => {
  return +parseFloat(value).toFixed(dp);
};

export {
  stringCompare,
  toCamelCase,
  firstName,
  ISOParser,
  decodeBase64,
  camelToNormal,
  toMonth,
  currency,
  substr,
  isMatch,
  isMatches,
  getUrl,
  getAssocId,
  formatDate,
  toFixedIfNecessary,
};
